.bootstrap-tagsinput
    background-color: #fff
    border: 1px solid #ccd0d2
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
    padding: 3px 6px
    display: inline-block
    line-height: 35px
    color: #555
    vertical-align: middle
    border-radius: 2px
    max-width: 100%
    width: 100%
    cursor: text

    input
        border: none
        box-shadow: none
        outline: none
        background-color: transparent
        padding: 0 6px
        margin: 0
        width: auto
        max-width: inherit
        
        &:focus
            border: none
            box-shadow: none

.bootstrap-tagsinput.form-control input::-moz-placeholder
    color: #777
    opacity: 1

.bootstrap-tagsinput.form-control input:-ms-input-placeholder
    color: #777

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder
    color: #777

.bootstrap-tagsinput .tag
    background: none repeat scroll 0 0 #CDE69C
    border: 1px solid #A5D24A
    color: #638421
    display: block
    float: left
    font-size: 11px
    line-height: 23px
    margin: 5px
    padding: 0 8px
    border-radius: 0
    font-family: "lucida grande", tahoma, verdana, arial, sans-serif

.bootstrap-tagsinput .tag [data-role="remove"]
    margin-left: 8px
    cursor: pointer

.bootstrap-tagsinput .tag [data-role="remove"]:after
    content: "x"
    padding: 0px 2px
